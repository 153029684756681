import { HypClientLib, HypClientLibApi, HypCollection } from "@hypericon/hypertable-client-lib";
import m from "mithril";

// Parcel
// if ((module as any).hot) {
//   (module as any).hot.accept(function () {
//     location.reload();
//   });
// }

console.log('Custom Views index page');

const api = new HypClientLibApi({
  targetOrigin: new URLSearchParams(window.location.search).get('target') ?? "https://hypertable.cloud",
});
const H = new HypClientLib(api);

// api.alertNotify("This came from custom views");

let loading = true;
let collection: HypCollection | undefined;
let alertResult: string | undefined;
let postMessages: any[] = [];

// setTimeout(() => {
  
  console.log(`Adding an event listener to window messages`);
  window.addEventListener("message", (ev => {
    const { origin, data } = ev;
    console.log(`messag from ${origin}: ${JSON.stringify(data).slice(0, 100)}`);
    postMessages.push({
      data,
      from: origin,
    });
    draw();
  }), false);

// }, 1000);

async function load() {
  collection = await H.getCollection("Features & Bugs").catch(err => {
    console.error(`Error getting collection:`, err);
    return undefined;
  });
  console.log(`got collection: ${collection?.name}`);
  // collection = await H.getCollectionById("ybJjPekMk5YmphwpmDAv5");
  loading = false;
  draw();
}

function draw() {

  const appDiv = window.document.querySelector("#app");
  if (!appDiv) return;

  m.render(appDiv, m(".space-y-4", [

    m(".flex.items-center.space-x-4", [

      m("button.px-2.py-1.bg-blue-500.text-white.rounded", {
        onclick: async () => {
          alertResult = String(await H.alertConfirm("Confirm something please", {
            confirmText: "Confirm",
            cancelText: "Cancel",
          }));
        },
      }, "Show Alert"),

      m(".space-x-2.flex.items-center", [
        m("label", "alert result:"),
        m("p", alertResult),
      ]),
    ]),

    loading
      ? m("p", "Loading...")
      : m("p", "Loaded!"),

    collection
      ? m("", [
        m("p", "Collection info:"),
        m("pre", [
          JSON.stringify(collection.toJson(), null, 2),
          // Object.keys(collection ?? {}).join(", "),
        ]),
      ])
      : m("p", "(no collection data)"),

    m("", [
      postMessages.map(msg => {
        return m("pre", JSON.stringify(msg));
      }),
    ]),

  ]));

}

draw();
load();


